import React, { useState } from "react"
import Layout from "../components/layout"
import { Tabs, Tab, Nav, Row, Col } from "react-bootstrap"
import SEO from "../components/seo"
import { graphql } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

const NQIndex = ({ data, location }) => {
  const [key, setKey] = useState("home")

  return (
    <Layout location={location}>
      <SEO title="NQ Index" />
      {/* <div className="page-headline">
        <div className="container">
          <div className="section-heading text-center">
            <h6 className="font-weight-bold text-uppercase text-white-50 flair">
              {page.title}
            </h6>
          </div>
        </div>
      </div> */}
      <div
        className="page-content nq-index"
        id="hero"
        style={{ padding: "150px 0" }}
      >
        <div className="">
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-lg-5">
              <StaticImage
                placeholder="dominantColor"
                className="img-fluid"
                src="../images/nq-index.svg"
                alt="About Image Vegetables"
                quality={100}
              />
            </div>
            <div
              className="col-lg-6 mx-auto d-flex"
              style={{ alignItems: "center" }}
            >
              <div className="px-5 pb-5" style={{ width: "500px" }}>
                <p
                  className="font-weight-bold text-white mb-0"
                  style={{ fontSize: "20px" }}
                >
                  Introducing a New Health Paradigm
                </p>
                <h2
                  className="section-title text-d-cyan mr-4 mb-0 py-3"
                  style={{ fontSize: "65px" }}
                >
                  The Game Changer: <br />
                  <span className="text-white">NuQ™ Index</span>
                </h2>
                <p
                  className="font-weight-bold text-white mb-0"
                  style={{ fontSize: "20px" }}
                >
                  Count Nutrients, NOT calories!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NQIndex
